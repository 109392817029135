body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: url('./bg.jpg');
  background-repeat: repeat;
  background-position: all;
  background-attachment: relative;
  background-size: 50px;
  background-color: #f1f1f1;

  &.text-right {
    .dropdown-menu {
      @extend .text-right;
      right: 0;
      left: auto;
    }
  }
}

#header {
  background-color: #1f1f1f !important;
  .main-navbar {
    .navbar-brand {
      img {
        filter: brightness(0) invert(1);
      }
    }
    .dropdown {
      @media (min-width: 768px) {
        &:hover {
          .dropdown-menu {
            display: block;
          }
        }
      }
    }
    .dropdown-menu {
      .dropdown-item {
        @extend .py-3;
        @extend .px-3;
        &:hover {
          @extend .bg-primary;
          @extend .text-white;
        }
      }
    }
    .nav-link {
      @extend .font-weight-bold;
      font-size: 13px;
      padding: 12px 9px;
      height: 45px;
      transition: all 0.3s ease;
      color: #ffffff;
      &.active,
      &:hover {
        @extend .bg-primary;
        @extend .text-white;
      }
    }
  }
}

.site-wrapper {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 60px;
    width: 60px;
    padding: 10px 10px;
  }
}

.site-wrapper {
  .side-slider {
    .image-gallery-slide-wrapper {
      height: 230px;
    }
    img {
      height: 100% !important;
      max-height: 100% !important;
      width: 100% !important;
    }
  }
}

.title-component {
  img {
    width: 120px;
  }
  @media (max-width: 700px) {
    img {
      width: 50px;
    }
    h4 {
      font-size: 14px;
    }
    p {
      font-size: 13px;
    }
  }
}

.activity-item {
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #f3f3f3;
  }
}

.publication-page,
.activity-page {
  border-radius: 2px;
  border-color: var(--primary) !important;
  border-width: 1px !important;
}

#footer {
  background-color: #282c2f;
}
